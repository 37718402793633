import { AdEvent } from 'types/businessTypes';

export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  // BigDecimal: any;
  // OffsetDateTime: any;
  UUID: string;
};

export const GRAPHQL_TYPENAME = '__typename';

export interface StringMap {
  [key: string]: string | null;
}

export interface Pair {
  first?: Maybe<Scalars['String']>;
  second?: Maybe<Scalars['String']>;
}

export interface ReferrerLocationState {
  referrer?: string;
}

// these are default values of React Bootstrap Table2, if they are changed consider using "sizePerPageList" property
export const getTablePageSize = (forAdmin: boolean): number[] => (forAdmin ? [10, 50, 100, 500] : [10, 50, 100]);
export const tablePageSize = [10, 50, 100];
export const adminTablePageSize = [10, 50, 100, 500];

export type Paging = {
  __typename?: 'Paging';
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  pageCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum LanguageCode {
  EN = 'en',
  DE = 'de',
  SE = 'se',
  FI = 'fi',
  RU = 'ru',
  ES = 'es',
  FR = 'fr',
  PT = 'pt',
}

export enum CountryCode {
  US = 'us',
  DE = 'de',
}

export enum Market {
  US = 'US',
  EUROPE = 'EUROPE',
}

export enum CurrencyCode {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  CHF = 'CHF',
}

export enum Location {
  UK = 'UK',
  US = 'US',
  EU = 'EU',
  ASIA = 'ASIA',
  GLOBAL = 'GLOBAL',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  ANY = 'ANY',
}

export enum Market {
  US = 'US',
  EUROPE = 'EUROPE',
}

export enum SocialMediaChannel {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  PINTEREST = 'PINTEREST',
  SNAPCHAT = 'SNAPCHAT',
  TIK_TOK = 'TIK_TOK',
}

export type Translation = {
  languageCode: LanguageCode;
  value: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Country>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  countryIsoName?: Maybe<Scalars['String']>;
  countryIsoAlpha2?: Maybe<CountryCode>;
  countryIsoAlpha3?: Maybe<Scalars['String']>;
};

export type AppConfig = {
  imagekitUrl: Scalars['String'];
  imagekitPublicKey: Scalars['String'];
  imagekitAuthEndpoint: Scalars['String'];
};

export enum SecurityRole {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',

  PG_USER = 'pg_user',

  CLIENT_ADMIN = 'client_admin',
  CLIENT_USER = 'client_user',

  SAAS_ADMIN = 'saas_admin',
  SAAS_USER = 'saas_user',

  CREATOR = 'creator',
}

export type Users = {
  items: Array<User>;
  paging: Paging;
};

export type User = {
  id: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  role: SecurityRole;
  emailConfirmed: Scalars['Boolean'];
  newEmail?: Maybe<Scalars['Boolean']>;
  disabled: Scalars['Boolean'];
  invitationCode?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  settings: UserSettings;
  clientId?: Maybe<Scalars['String']>;
  clientTeamRole?: Maybe<ClientTeamRole>;
  whitelabel?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  lastModifiedDate?: Maybe<Scalars['String']>;
};

export type UserSettings = {
  languageIso6391: LanguageCode;
  projectViewLayout: ProjectViewLayout;
  dataColumnNames: Maybe<Array<Scalars['String']>>;
  dataColumnsConfigs: Maybe<Array<DataColumnsConfig>>;
};

export type DataColumnsConfig = {
  name: string;
  columnNames: Array<string>;
};

export enum ProjectViewLayout {
  LIST = 'LIST',
  GRID = 'GRID',
}

export enum UserNotificationType {
  AD_ACTIVITY_UPDATES = 'ACTIVITY_UPDATES',
  AD_REVIEW_REMINDER = 'AD_REVIEW_REMINDER',
  MENTIONS = 'MENTIONS',
  DEADLINE_REMINDER = 'DEADLINE_REMINDER',
}

export type Invitations = {
  items: Array<Invitation>;
  paging: Paging;
};

export type Invitation = {
  code: Scalars['ID'];
  usedDate?: Maybe<Scalars['String']>;
  usedByUserId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  whitelabel?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
};

export type CreatorUser = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  createdDate: Scalars['String'];
};

export type PgTeamMember = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Clients = {
  __typename?: 'Users';
  items: Array<Client>;
  paging: Paging;
};

export type Client = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  paymentModels: Array<ClientPaymentModel>;
  whitelabel?: Maybe<Scalars['String']>;
  mobileApp?: Maybe<Scalars['Boolean']>;
  currency: CurrencyCode;
  slackChannel?: Maybe<Scalars['String']>;

  painPoints?: Maybe<Array<Scalars['String']>>;
  productFeatures?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  benefits?: Maybe<Array<Scalars['String']>>;
  valuePropositions?: Maybe<Array<Scalars['String']>>;
  personas?: Maybe<Array<ClientPersona>>;
  ctas?: Maybe<Array<Scalars['String']>>;

  creativeLeads?: Maybe<Array<Scalars['String']>>;
  creativeStrategists?: Maybe<Array<Scalars['String']>>;
  graphicDesigners?: Maybe<Array<Scalars['String']>>;
  motionDesigners?: Maybe<Array<Scalars['String']>>;
  copywriters?: Maybe<Array<Scalars['String']>>;
  creators?: Maybe<Array<Scalars['String']>>;
  ideators?: Maybe<Array<Scalars['String']>>;

  logoFilename?: Maybe<Scalars['String']>;
  logoContent?: Maybe<Scalars['String']>;
  appScreenFilename?: Maybe<Scalars['String']>;
  appScreenContent?: Maybe<Scalars['String']>;
  productFilename?: Maybe<Scalars['String']>;
  productContent?: Maybe<Scalars['String']>;
  visualFeatures?: Maybe<Array<Scalars['String']>>;

  disabledHoldAttentionFields?: Maybe<Array<HoldAttentionFeatures>>;

  facebookAdAccountId?: Maybe<Scalars['String']>;
  facebookImportExternalAds?: Maybe<Scalars['Boolean']>;
  facebookAdEvents?: Maybe<Array<AdEvent>>;
  facebookUgcCampaignTags?: Maybe<Array<AdEvent>>;
  facebookUgcAdSetTags?: Maybe<Array<AdEvent>>;
  facebookUgcAdTags?: Maybe<Array<AdEvent>>;

  tikTokAdvertiserId?: Maybe<Scalars['String']>;
  tikTokImportExternalAds?: Maybe<Scalars['Boolean']>;
  tikTokAdEvents?: Maybe<Array<AdEvent>>;

  asanaIds?: Maybe<Array<Scalars['String']>>;

  airtableId?: Maybe<Scalars['String']>;
  airtableAdConceptId?: Maybe<Scalars['String']>;
  airtableAbTestId?: Maybe<Scalars['String']>;
  airtablePerformanceId?: Maybe<Scalars['String']>;
  airtableRoadmapId?: Maybe<Scalars['String']>;
  airtableBacklogId?: Maybe<Scalars['String']>;

  everhourIds?: Maybe<Array<Scalars['String']>>;
  disabledProducts?: Maybe<Array<PaintgunProducts>>;

  betaConstant?: Maybe<Scalars['Float']>;

  starMetric?: Maybe<ClientStarMetric>;

  metaDataSource?: Maybe<DataSource>;
  tikTokDataSource?: Maybe<DataSource>;

  createdDate: Scalars['String'];
  lastModifiedDate?: Maybe<Scalars['String']>;
};

export enum PaintgunProducts {
  TEST = 'Test',
  ASSETS = 'Assets',
  DATA = 'Data',
  ANALYTICS = 'Analytics',
}

export enum HoldAttentionFeatures {
  PAIN_POINTS = 'Pain points',
  PERSONAS = 'Personas',
  BENEFITS = 'Benefits',
  PRODUCT_FEATURES = 'Product features',
  PRODUCTS = 'Products',
  VALUE_PROPOSITIONS = 'Value propositions',
}

export enum ClientPaymentModel {
  STANDARD = 'STANDARD',
  PERFORMANCE = 'PERFORMANCE',
}

export enum ClientTeamRole {
  APPROVER = 'APPROVER',
  REVIEWER = 'REVIEWER',
}

export enum AdAccountConversionWindow {
  _28d_click = '_28d_click',
  _7d_click = '_7d_click',
  _1d_click = '_1d_click',
  _28d_view = '_28d_view',
  _7d_view = '_7d_view',
  _1d_view = '_1d_view',

  _28d_click_1d_view = '_28d_click_1d_view',
  _28d_click_28d_view = '_28d_click_28d_view',
  _28d_click_7d_view = '_28d_click_7d_view',
  _7d_click_28d_view = '_7d_click_28d_view',
  _7d_click_7d_view = '_7d_click_7d_view',
  _7d_click_1d_view = '_7d_click_1d_view',
  _1d_click_28d_view = '_1d_click_28d_view',

  _1d_click_7d_view = '_1d_click_7d_view',
  _1d_click_1d_view = '_1d_click_1d_view',
  _1d_engaged_view = '_1d_engaged_view',
  _1d_view_first_conversion = '_1d_view_first_conversion',
  _7d_view_first_conversion = '_7d_view_first_conversion',
  _28d_view_first_conversion = '_28d_view_first_conversion',
  _1d_click_first_conversion = '_1d_click_first_conversion',

  _7d_click_first_conversion = '_7d_click_first_conversion',
  _28d_click_first_conversion = '_28d_click_first_conversion',
}

export enum AdAccountReportTime {
  ON_CONVERSION_DATE = 'ON_CONVERSION_DATE',
  ON_IMPRESSION_DATE = 'ON_IMPRESSION_DATE',
  MIXED = 'MIXED',
}

export enum MetaAdEvent {
  // AMOUNT_SPENT = 'spend',
  APP_INSTALL = 'app_install',
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_WISHLIST = 'add_to_wishlist',
  COMPLETE_REGISTRATION = 'complete_registration',
  CONTACT = 'contact',
  CUSTOMIZE_PRODUCT = 'customize_product',
  DONATE = 'donate',
  FIND_LOCATION = 'find_location',
  INITIATE_CHECKOUT = 'initiate_checkout',
  LEAD = 'lead',
  PURCHASE = 'purchase',
  SCHEDULE = 'schedule',
  SEARCH = 'search',
  START_TRIAL = 'start_trial',
  SUBMIT_APPLICATION = 'submit_application',
  SUBSCRIBE = 'subscribe',
  VIEW_CONTENT = 'view_content',
  // CUSTOM_EVENT_1 = 'Custom event 1',
  // CUSTOM_EVENT_2 = 'Custom event 2',
  // AD_SCORE_NORTHSTAR_1_FBETA = 'Ad score, northstar 1 (fbeta)',
  // AD_SCORE_NORTHSTAR_1_GEOMM = 'Ad score, northstar 1 (geomm)',
  // AD_SCORE_NORTHSTAR_2_FBETA = 'Ad score, northstar 2 (fbeta)',
  // AD_SCORE_NORTHSTAR_2_GEOMM = 'Ad score, northstar 2 (geomm)',
}

export enum TikTokAdEvent {
  SPEND = 'spend',
  APP_INSTALL = 'App install',
  ADD_PAYMENT_INFO = 'Add Payment Info',
  ADD_TO_CART = 'Add to Cart',
  ADD_TO_WISHLIST = 'Add to Wishlist',
  COMPLETE_REGISTRATION = 'Complete Registration',
  CONTACT = 'Contact',
  CUSTOMIZE_PRODUCT = 'Customize Product',
  DONATE = 'Donate',
  FIND_LOCATION = 'Find Location',
  INITIATE_CHECKOUT = 'Initiate Checkout',
  LEAD = 'Lead',
  PURCHASE = 'Purchase',
  SCHEDULE = 'Schedule',
  SEARCH = 'Search',
  START_TRIAL = 'Start Trial',
  SUBMIT_APPLICATION = 'Submit Application',
  SUBSCRIBE = 'Subscribe',
  VIEW_CONTENT = 'View Content',
  CUSTOM_EVENT_1 = 'Custom event 1',
  CUSTOM_EVENT_2 = 'Custom event 2',
  AD_SCORE_NORTHSTAR_1_FBETA = 'Ad score, northstar 1 (fbeta)',
  AD_SCORE_NORTHSTAR_1_GEOMM = 'Ad score, northstar 1 (geomm)',
  AD_SCORE_NORTHSTAR_2_FBETA = 'Ad score, northstar 2 (fbeta)',
  AD_SCORE_NORTHSTAR_2_GEOMM = 'Ad score, northstar 2 (geomm)',
  // CLICK_BUTTON = 'Click Button',
  // COMPLETE_PAYMENT = 'Complete Payment',
  // DOWNLOAD = 'Download',
  // PLACE_AN_ORDER = 'Place an Order',
  // SUBMIT_FORM = 'Submit Form',
  // PG_EVENT_MATCHED_1 = 'PG Event Matched 1',
  // PG_EVENT_MATCHED_2 = 'PG Event Matched 2',
  // PG_EVENT_MATCHED_3 = 'PG Event Matched 3',
  // PG_EVENT_MATCHED_4 = 'PG Event Matched 4',
  // PG_EVENT_MATCHED_5 = 'PG Event Matched 5',
  // PG_EVENT_MATCHED_6 = 'PG Event Matched 6',
  // PG_EVENT_MATCHED_7 = 'PG Event Matched 7',
  // PG_EVENT_MATCHED_8 = 'PG Event Matched 8',
  // PG_EVENT_MATCHED_9 = 'PG Event Matched 9',
  // PG_EVENT_MATCHED_10 = 'PG Event Matched 10',
}

export type DataSource = {
  createdByName: Scalars['String'];
  createdByAdAccountId: Scalars['String'];
  adAccounts?: Maybe<Array<DataSourceAdAccount>>;
};

export type DataSourceAdAccount = {
  id: Scalars['String'];
  name: Scalars['String'];
  conversionWindow?: Maybe<AdAccountConversionWindow>;
  reportTime?: Maybe<AdAccountReportTime>;
  events?: Maybe<Array<Scalars['String']>>;
  starMetric1?: Maybe<Scalars['String']>;
  starMetric2?: Maybe<Scalars['String']>;
};

export type ClientPersona = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ClientTeamMember = {
  __typename?: 'ClientTeamMember';
  id: Scalars['String'];
  invitationCode?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  teamRole: ClientTeamRole;
  createdDate?: Maybe<Scalars['String']>;
};

export enum ClientStarMetric {
  REGISTRATION = 'REGISTRATION',
  PURCHASE = 'PURCHASE',
  ADD_TO_WAITING_LIST = 'ADD_TO_WAITING_LIST',
}

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  settings: Array<UserNotificationSetting>;
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  email: Scalars['Boolean'];
  slack: Scalars['Boolean'];
  type: Scalars['String'];
};

export type Whitelabels = {
  items: Array<Whitelabel>;
  paging: Paging;
};

export type Whitelabel = {
  id: Scalars['String'];
  domain: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  logoContent?: Maybe<Scalars['String']>;
  logoName?: Maybe<Scalars['String']>;
  faviconContent?: Maybe<Scalars['String']>;
  faviconName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
};
